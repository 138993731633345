import * as React from "react"

// markup
const Footer = () => {
    
return (
    <div className="max-w-screen-xl mt-12 flex border-t border-pale-grey mx-5 xl:mx-auto">
        <p className="font-sans text-xs font-normal italic text-battleship-grey text-center w-full mt-12"> ©2022 Charlotte Botermans. All rights reserved.</p>   
    </div>
    )
}

export default Footer