import * as React from "react"
import { Helmet } from "react-helmet"

// markup
const SEO = (props) => {
    
return props.children ? (
        <Helmet>
            <title>{props.children} | Charlotte Botermans</title>
        </Helmet>
    ) : (
        <Helmet>
            <title>Charlotte Botermans</title>
        </Helmet>
    )
}

export default SEO