import React, { Fragment } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import MenuItem from "../components/menu-item"
import MenuItemMobile from "../components/menu-item-mobile"
import MenuIconItem from "../components/menu-icon-item"
import MenuIconItemMobile from "../components/menu-icon-item-mobile"
import MediumIcon from "../images/icon-medium.svg"
import LinkedinIcon from "../images/icon-linkedin.svg"
import { document } from "browser-monads"



import { Disclosure, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"

// markup
const Menu = () => {

    function removeScroll(open) {
        if(open) {
            document.documentElement.classList.add('is-locked');
            // document.documentElement.classList.add('overflow-hidden');
        }
        else {
            document.documentElement.classList.remove('is-locked');
            // document.documentElement.classList.remove('overflow-hidden');
        }

        return null
    }
    
    return (
        <Disclosure>
            {({ open }) => (
                <Fragment>
                    {removeScroll(open)}
                    <div className={clsx("max-w-screen-xl mt-12 flex mx-5 xl:mx-auto bg-white z-10", { "sticky top-0": open})}> 
                        <div className="h-10 leading-10">
                            <Link className="font-sans text-xs uppercase font-medium text-battleship-grey" to="/">Charlotte Botermans</Link>
                        </div>
                        <div className="text-right flex-grow h-10 leading-10"> 
                            
                                    <MenuItem className="hidden sm:inline" to="/">Work</MenuItem>
                                    <MenuItem className="hidden sm:inline" to="/about/">About</MenuItem>
                                    <MenuItem className="hidden sm:inline" to="/contact/">Contact</MenuItem>
                                    <MenuIconItem to="https://medium.com/@charlottebotermans" className="ml-10 hidden sm:inline"> <MediumIcon className="fill-current inline-block h-4"/></MenuIconItem>
                                    <MenuIconItem to="https://www.linkedin.com/in/charlotte-botermans/" className="hidden sm:inline"> <LinkedinIcon className="fill-current inline-block h-4"/></MenuIconItem>
                                
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 sm:hidden">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                        </div>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Disclosure.Panel className="sm:hidden w-full z-10 sticky top-10">
                            <div className="absolute h-screen w-full pt-2 pb-3 space-y-1 bg-white text-center">
                                <MenuItemMobile to="/">Work</MenuItemMobile>
                                <MenuItemMobile to="/about/">About</MenuItemMobile>
                                <MenuItemMobile to="/contact/">Contact</MenuItemMobile>
                                <div className="flex justify-center pt-12">
                                    <MenuIconItemMobile to="https://medium.com/@charlottebotermans" className="w-12"> <MediumIcon className="fill-current inline-block h-4"/></MenuIconItemMobile>
                                    <MenuIconItemMobile to="https://www.linkedin.com/in/charlotte-botermans/" className="w-12"> <LinkedinIcon className="fill-current inline-block h-4"/></MenuIconItemMobile>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </Transition>
                 </Fragment>
            )}
        </Disclosure>
    )
}

export default Menu