import clsx from "clsx"

export const subtitle = clsx(
        "font-sans",
        "text-xs",
        "uppercase",
        "font-medium",
        "text-ford-grey",
);

export const title = clsx(
    "font-serif",
    "text-xl",
    "font-medium",
    "text-battleship-grey",
    "text-left",
    "w-4/6",
    "md:w-full",
    "mx-auto",
);

export const requiredForm = clsx(
    "text-dark-lavender",
    "text-xs", 
    "font-sans",
);

export const input = clsx(
    "mt-0",
    "block",
    "w-full",
    "border-0", 
    "border-b", 
    "border-pale-grey",
    "focus:ring-0", 
    "focus:dark-lavender", 
    "focus:bg-transparent",
);

export const textContainer = clsx(
    "text-left",
    "font-sans",
    "text-sm",
    "font-normal",
    "text-battleship-grey",
    "my-2",
    "w-4/6",
    "md:w-full",
    "mx-auto",
    "mt-4",
);