import * as React from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import { window } from "browser-monads"
import { subtitle } from "../styles"

// markup
const MenuItemMobile = (props) => {
    const style = clsx(
        "hover:text-battleship-grey",
        "block",
        "w-full", 
        "py-4",
        subtitle,
        {
            'text-battleship-grey': props.to === window.location.pathname
        },
        props.className
    )

    return (
        <Link className={style} to={props.to}>{props.children}</Link>
    )
}

export default MenuItemMobile
