import * as React from "react"
import clsx from "clsx"
// markup
const MenuIconItemMobile = (props) => {
    const style = clsx(
        "font-sans",
        "text-xs",
        "uppercase",
        "font-medium",
        "hover:text-battleship-grey",
        "text-ford-grey",
        props.className
    )

    return (
        <a className={style} href={props.to} target="_blank" rel="noreferrer">{props.children}</a>
    )
}

export default MenuIconItemMobile
